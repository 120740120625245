var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:3",
            expression: "'required|min:3'"
          }
        ],
        staticClass: "w-full",
        attrs: {
          "data-vv-validate-on": "blur",
          name: "emailOrUsername",
          "icon-no-border": "",
          icon: "icon icon-user",
          "icon-pack": "feather",
          "label-placeholder": "Username or email"
        },
        model: {
          value: _vm.emailOrUsername,
          callback: function($$v) {
            _vm.emailOrUsername = $$v
          },
          expression: "emailOrUsername"
        }
      }),
      _c(
        "div",
        { staticClass: "flex flex-wrap justify-between mb-3 mt-5" },
        [
          _c(
            "vs-button",
            {
              attrs: { disabled: !_vm.emailOrUsername },
              on: { click: _vm.restorePassword }
            },
            [_vm._v("Send")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }