<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-1/5 sm:m-0 m-4 m">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">

            <vx-card v-if="!restorePasswordSubmitted" no-radius no-shadow>
              <div class="vx-card__title mb-4">
                <h4 class="mb-4">{{ $t('auth.restore_password.title') }}</h4>
                <p>{{ $t('auth.restore_password.instructions') }}</p>
              </div>
              <user-password-forget @submit="restorePasswordSubmitted = true"></user-password-forget>
            </vx-card>

            <vx-card v-else no-radius no-shadow>
              <div class="vx-card__title mb-4">
                <h4 class="mb-4">{{ $t('auth.restore_password.title') }}</h4>
                <p>{{ $t('auth.restore_password.confirmation') }}</p>
              </div>
              <div class="flex flex-wrap justify-between mb-3 mt-5">
                <vs-button @click="redirectToLoginPage">OK</vs-button>
              </div>
            </vx-card>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import UserPasswordForget from '../Components/UserPasswordForget.vue'

export default {
  components: {
    UserPasswordForget
  },
  data () {
    return {
      restorePasswordSubmitted: false,
    }
  },
  methods: {
    redirectToLoginPage() {
      this.$router.push(`/${this.$route.params.language}/login`)
    },
  }
}
</script>
