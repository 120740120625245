var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "login" }
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-1/5 sm:m-0 m-4 m"
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center"
                  },
                  [
                    !_vm.restorePasswordSubmitted
                      ? _c(
                          "vx-card",
                          { attrs: { "no-radius": "", "no-shadow": "" } },
                          [
                            _c("div", { staticClass: "vx-card__title mb-4" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("auth.restore_password.title"))
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("auth.restore_password.instructions")
                                  )
                                )
                              ])
                            ]),
                            _c("user-password-forget", {
                              on: {
                                submit: function($event) {
                                  _vm.restorePasswordSubmitted = true
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "vx-card",
                          { attrs: { "no-radius": "", "no-shadow": "" } },
                          [
                            _c("div", { staticClass: "vx-card__title mb-4" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("auth.restore_password.title"))
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("auth.restore_password.confirmation")
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap justify-between mb-3 mt-5"
                              },
                              [
                                _c(
                                  "vs-button",
                                  { on: { click: _vm.redirectToLoginPage } },
                                  [_vm._v("OK")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }