<template>
  <div>
    <vs-input
        v-validate="'required|min:3'"
        data-vv-validate-on="blur"
        name="emailOrUsername"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Username or email"
        v-model="emailOrUsername"
        class="w-full"
    />

    <div class="flex flex-wrap justify-between mb-3 mt-5">
      <vs-button :disabled="!emailOrUsername" @click="restorePassword">Send</vs-button>
    </div>
  </div>
</template>

<script>

import AuthServices from '@/modules/Auth/Services/AuthServices.js'

export default {
  data () {
    return {
      emailOrUsername: '',
    }
  },
  methods: {
    isEmail(value) {
      const re = /^[^@]+@[^@]+\.[^@]+$/
      return re.test(value);
    },
    restorePassword() {
      const field = this.isEmail(this.emailOrUsername) ? 'email' : 'username'
      AuthServices.restorePassword(field, this.emailOrUsername)
      this.$emit("submit");
    },
  }
}

</script>

